<template>
  <v-container v-if="isReady" id="Assignment" fluid tag="section" class="px-8">
    <v-row>
      <v-col cols="12" lg="9" md="9">
        <v-card class="mb-5" data-test="assignment-overview-card">
          <v-col cols="12">
            <v-row no-gutters align="center">
              <p class="font-weight-medium mt-5 px-7" style="font-size: 20px">
                {{ assignmentData.name }}
                <v-btn
                  v-if="canUpdateAssignment"
                  text
                  dense
                  color="blue"
                  :href="`/courses/${course.id}/assignments/${assignment.id}/edit`"
                  @click.prevent="goToEditAssignment"
                >
                  {{ $t('edit') }}
                  <v-icon class="ml-2" x-small>
                    {{ $i('common.edit') }}
                  </v-icon>
                </v-btn>
              </p>
            </v-row>

            <v-tabs v-model="selectedTab" color="primary" class="pl-3">
              <v-tab v-for="i in tabs" :key="i.tab">
                {{ $t('assignment-overview-overview') }}
              </v-tab>
              <v-tab-item>
                <v-card-text class="tab-text-material">
                  <v-card-text>
                    {{ assignmentData.overviewText }}
                  </v-card-text>
                </v-card-text>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-card>

        <v-dialog
          v-model="dialogs.addQuestionsToAssignment"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <task-gallery
            :selected-task-ids="selectedTaskIds"
            @add-tasks="onAddTasks"
            @close="dialogs.addQuestionsToAssignment = false"
          />
        </v-dialog>
        <v-card
          v-if="canUpdateAssignment || canAssignAssignment"
          class="pa-3"
          data-test="assignment-tasks-card"
        >
          <v-row align="end" no-gutters>
            <v-col>
              <div class="d-inline-block buttons-full-width pl-7">
                <div class="task-buttons">
                  <p class="d-inline font-weight-bold mr-4" style="font-size: 20px">
                    {{ $t('questions').toUpperCase() }}
                  </p>
                  <v-tooltip top>
                    <template v-slot:activator="{on, attrs}">
                      <span v-on="on">
                        <v-btn
                          elevation="4"
                          small
                          v-bind="attrs"
                          color="secondary"
                          :disabled="!canUpdateAssignment"
                          data-test="add-task-button"
                          data-test-tooltip-activator="add-task-button"
                          @click.stop="dialogs.addQuestionsToAssignment = true"
                        >
                          <v-icon left medium>mdi-plus</v-icon>
                          {{ $t('Add Tasks') }}
                        </v-btn>
                      </span>
                    </template>
                    <span data-test-tooltip-content-for="add-task-button">{{
                      $t(
                        canUpdateAssignment
                          ? 'assignmentPage.tooltips.addTask'
                          : 'assignmentPage.noEditPermission'
                      )
                    }}</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{on, attrs}">
                      <span v-on="on">
                        <v-btn
                          :disabled="selectedRows.length === 0 || !canUpdateAssignment"
                          small
                          outlined
                          elevation="4"
                          v-bind="attrs"
                          color="secondary"
                          data-test="remove-task-button"
                          data-test-tooltip-activator="remove-task-button"
                          @click="onRemoveSelected"
                        >
                          <v-icon left medium>mdi-delete</v-icon>
                          {{ $t('Delete Tasks') }}
                        </v-btn>
                      </span>
                    </template>
                    <span data-test-tooltip-content-for="remove-task-button">{{
                      $t(
                        canUpdateAssignment
                          ? 'assignmentPage.tooltips.removeSelected'
                          : 'assignmentPage.noEditPermission'
                      )
                    }}</span>
                  </v-tooltip>
                </div>
                <v-tooltip top>
                  <template v-slot:activator="{on, attrs}">
                    <v-btn
                      class="float-right"
                      fab
                      large
                      elevation="4"
                      v-bind="attrs"
                      color="secondary"
                      :disabled="missingFormData.length !== 0"
                      v-on="on"
                      @click="saveAndPreview"
                    >
                      <v-icon>{{ $i('assignment.previewAssignment') }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('assignmentPage.tooltips.previewAssignment') }}</span>
                </v-tooltip>
              </div>
            </v-col>
          </v-row>

          <v-row class="stemble-task-grid mt-3" no-gutters>
            <ag-grid-vue
              id="QuestionsAssignmentGrid"
              :row-data-model="assignmentData.taskAssignments"
              style="width: 100%; height: 350px"
              class="ag-theme-material"
              always-show-horizontal-scroll
              :row-selection="rowSelection"
              :grid-options="gridOptions"
              :column-defs="columnDefs"
              :default-col-def="defaultColDef"
              :animate-rows="true"
              :sorting-order="sortingOrder"
              :overlay-loading-template="overlayLoadingTemplate"
              :overlay-no-rows-template="overlayNoRowsTemplate"
              :components="components"
              :suppress-row-click-selection="true"
              :post-process-popup="postProcessPopup"
              :allow-context-menu-with-control-key="true"
              :icons="icons"
              :framework-components="frameworkComponents"
              :enter-navigates-vertically-after-edit="true"
              :row-drag-managed="canUpdateAssignment"
              @data-model-changed="
                assignmentData.taskAssignments = $event;
                hasChangesInAssignmentData = true;
              "
              @row-drag-end="onRowDragEnd"
              @row-selected="updateSelectedRows()"
              @grid-ready="onGridReady"
            />
          </v-row>

          <v-row v-if="false" class="mt-8" no-gutters>
            <!-- TODO: re-enable once progress is available -->
            <v-col cols="12">
              <v-col cols="1">
                {{ $t('completed') }}
              </v-col>
              <v-col cols="12">
                <v-progress-linear v-model="complatedQuizPercentage" color="primary" height="25">
                  <template v-slot="{value}">
                    <strong>{{ Math.ceil(value) }}%</strong>
                  </template>
                </v-progress-linear>
              </v-col>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" lg="3" md="3">
        <course-like-assignment-info
          :total-assignment-marks="totalAssignmentMarks"
          :assignment-grade="isGradeLocked ? 'locked' : assignmentGrade"
          :is-new-assignment="isNew"
          :missing-form-data="missingFormData"
          :has-changes="hasChangesInAssignmentData"
          :course-like-assignments.sync="assignmentData.courseLikeAssignments"
          :can-update-assignment="canUpdateAssignment"
          :can-assign-assignment="canAssignAssignment"
          :course="course"
          :assignment="assignment"
          :assignment-owner-id.sync="assignmentData.ownerId"
          :is-assignment-saving="isAssignmentSaving"
          :assignment-extension="latestExtension"
          :owner-options="ownerOptions"
          update-mode
          @save-assignment="saveAssignmentHandler"
        />
      </v-col>
    </v-row>
    <try-it-dialog :task="previewTask" @close="previewTask = null" />
    <s-confirmation-dialog
      v-model="loseAccessDialogOpened"
      :title="$t('assignmentPage.loseAccessDialog.title').toString()"
      :content="$t('assignmentPage.loseAccessDialog.content').toString()"
      :confirm-button-options="{
        text: $t('assignmentPage.saveAssignment').toString(),
        color: 'danger',
      }"
      :confirm-action="() => saveAssignmentHandler([true, shouldRedirectToAssignmentSettings])"
    />
    <s-confirmation-dialog
      v-model="redirectingToInertiaDialog"
      title="Redirecting"
      content="You have unsaved changes. Would you like to save them before editing the assignment settings?"
      :confirm-button-options="{
        text: $t('assignmentPage.saveAssignment').toString(),
        color: 'primary',
      }"
      :confirm-action="() => saveAssignmentHandler([false, true])"
      :cancel-action="() => (shouldRedirectToAssignmentSettings = false)"
    />
  </v-container>
</template>

<script>
import {AgGridVue} from 'ag-grid-vue';
import CourseLikeAssignment from '@/assignments/models/CourseLikeAssignment';
import AssignmentGrade from '@/grades/models/AssignmentGrade';
import Assignment from '@/assignments/models/Assignment';
import {Course, CourseSection} from '@/courses/models';
import TaskGallery from '@/assignments/views/TaskGallery';
import TaskAssignment from '@/tasks/models/TaskAssignment';
import EditIconInCellRenderer from '@/assignments/components/EditIconInCellRenderer';
import {LoadingFlag} from '@/loading/types/LoadingFlags';
import LatexRenderer from '@/assignments/components/LatexRenderer';
import TaskTableCellRenderer from '@/assignments/components/TaskTableCellRenderer';
import CourseLikeAssignmentInfo from '@/assignments/components/CourseLikeAssignmentInfo';
import {ASSIGNMENT_OVERVIEW, QUIZ} from '@/router/route-names';
import moment from 'moment';
import TryItDialog from '@/tasks/components/TryItDialog';
import AssignmentExtension from '@/assignments/models/AssignmentExtension';
import NumericCellEditorBasic from '@/common/components/NumericCellEditorBasic';
import isEqual from 'lodash.isequal';
import {getAllChanged} from '@/common/utils/getAllChanged';
import Gate from '@/permissions/Gate';
import {inject} from '@/container';
import {buildBatch} from '@/assignments/api/course-like-assignments';
import SConfirmationDialog from '@/common/components/SConfirmationDialog';
import CourseRole from '@/users/models/CourseRole';
import {buildDefaultGridOptions} from '@/ag-grid/utils/buildDefaultGridOptions';
import STextarea from '@/common/components/STextarea.vue';

export default {
  name: 'Assignment',
  components: {
    STextarea,
    TryItDialog,
    CourseLikeAssignmentInfo,
    TaskGallery,
    AgGridVue,
    SConfirmationDialog,
  },
  props: {
    course: {
      type: Course,
      required: true,
    },
    courseId: {
      type: Number,
      required: true,
    },
    assignment: {
      type: Object,
      required: true,
    },
    assignmentId: {
      type: [String, Number],
      required: true,
    },
    latestExtension: {
      type: AssignmentExtension,
      default: null,
    },
  },
  setup: (props) => {
    document.title = `${props.course.courseCode} - ${props.assignment.name} - Overview | Stemble`;
  },
  data() {
    return {
      previewTask: null,
      selectedRows: [],
      showMissingAssignmentData: false,
      dialogs: {
        addQuestionsToAssignment: false,
      },
      inlineEdits: {
        editOverviewText: false,
        editAssignmentName: false,
      },
      questions: 'QUESTIONS ',
      sectionChips: ['Section A', 'Section B', 'Section C', 'Section D'],
      sectionItems: ['Section A', 'Section B', 'Section C', 'Section D'],
      tab: null,
      max25chars: (v) => v.length <= 25 || 'Input too long!',
      selectedTab: null,
      gridOptions: null,
      columnApi: null,
      defaultColDef: null,
      rowSelection: null,
      components: null,
      postProcessPopup: null,
      getRowNodeId: null,
      sheet: false,
      icons: null,
      editType: null,
      frameworkComponents: null,
      // Stemble
      isGradeLocked: false,
      taskAssignmentGridApi: null,
      removedTaskAssignments: [],
      originalAssignmentData: null,
      assignmentData: {
        id: null,
        name: null,
        queuedName: null,
        overviewText: '',
        queuedOverviewText: '',
        courseLikeAssignments: [],
        taskAssignments: [],
        ownerId: null,
      },
      redirectingToInertiaDialog: false,
      loseAccessDialogOpened: false,
      shouldRedirectToAssignmentSettings: false,
      hasChangesInAssignmentData: false,
    };
  },
  computed: {
    assignmentGrade() {
      return (
        AssignmentGrade.queryByUserAssignment(this.$auth.user.id, this.assignment.id)
          .with('gradeAdjustments')
          .first() || null
      );
    },
    ownerOptions() {
      const course = Course.find(this.course.id);
      const sections = CourseSection.query().where('courseId', this.course.id).get();
      return [course, ...sections].filter((courseLike) => {
        return this.$gate.can('create', 'Assignment', courseLike.id);
      });
    },
    columnDefs() {
      return [
        {
          headerName: this.$t('question-number'),
          field: 'orderingIndex',
          checkboxSelection: this.canUpdateAssignment,
          sortable: false,
          width: 80,
          suppressMenu: true,
          filter: false,
          suppressMovable: true,
          rowDrag: this.canUpdateAssignment,
        },
        {
          headerName: this.$t('title'),
          field: 'task.title',
          sortable: false,
          width: 175,
          suppressMenu: true,
          filter: false,
          suppressMovable: true,
          cellRendererFramework: LatexRenderer,
        },
        {
          headerName: this.$t('question-weight'),
          field: 'pointValue',
          cellClass: 'ag-column-style-link',
          editable: this.canUpdateAssignment,
          stopEditingWhenCellsLosesFocus: true,
          singleClickEdit: true,
          width: 50,
          filter: false,
          cellEditorPopup: false,
          cellRendererFramework: EditIconInCellRenderer,
          cellRendererParams: {
            translationKey: 'assignmentPage.tooltips.editWeight',
          },
          suppressMenu: true,
          suppressMovable: true,
          cellEditorFramework: NumericCellEditorBasic,
          cellEditorParams: {
            min: 0,
          },
        },
        {
          headerName: 'Preview',
          headerClass: 'hide-header-preview',
          field: 'preview',
          suppressMenu: true,
          filter: false,
          width: 40,
          sortable: false,
          suppressMovable: true,
          cellRendererFramework: TaskTableCellRenderer,
          cellRendererParams: {
            canUpdateAssignment: this.canUpdateAssignment,
            viewPreview: ({data}) => {
              this.openPreviewTask(data);
            },
          },
        },
      ];
    },
    missingFormData() {
      return this.getMissingFormData();
    },
    selectedTaskIds() {
      return this.assignmentData.taskAssignments.map((taskAssignment) => taskAssignment.taskId);
    },
    canUpdateAssignment() {
      if (this.isNew) {
        return this.$gate.can('createIn', 'Assignment', this.courseId);
      } else {
        return this.$gate.can('update', 'Assignment', this.assignment);
      }
    },
    canAssignAssignment() {
      if (this.isNew) {
        return this.$gate.can('assignAssignmentsIn', 'CourseLike', this.courseId);
      }

      return this.course.sectionIds
        .concat([this.courseId])
        .reduce(
          (canAssignAny, courseLikeId) =>
            canAssignAny || this.$gate.can('assignIn', 'Assignment', this.assignment, courseLikeId),
          false
        );
    },
    quizTo() {
      return {
        name: QUIZ,
        params: this.$route.params,
      };
    },
    tabs() {
      return [
        {
          tab: this.$t('assignment-overview-overview'),
          content: this.assignmentData.overviewText,
        },
      ];
    },
    isReady() {
      return this.assignmentData;
    },
    isNew() {
      return this.assignmentId === 'new';
    },
    isAssignmentSaving() {
      return this.$loadingFlags.isLoading(LoadingFlag.SavingAssignment);
    },
    totalAssignmentMarks() {
      return this.assignmentData.taskAssignments.reduce(
        (acc, taskAssignment) => acc + taskAssignment.pointValue,
        0
      );
    },
    removedCourseLikeAssignments() {
      return getAllChanged(
        this.assignmentData.courseLikeAssignments,
        this.originalAssignmentData.courseLikeAssignments,
        (cla1, cla2) => cla1.courseLikeId === cla2.courseLikeId
      ).removed;
    },
  },
  watch: {
    assignmentData: {
      handler(assignmentData) {
        if (this.originalAssignmentData) {
          this.hasChangesInAssignmentData =
            this.hasChangesInAssignmentData ||
            !isEqual(assignmentData, this.originalAssignmentData);
        }
      },
      deep: true,
    },
  },
  created() {
    this.fetchAssignmentGrade();
    this.initializeAssignment();

    if (!this.assignmentData.ownerId) {
      this.assignmentData.ownerId = this.getDefaultOwnerId();
    }
  },
  beforeMount() {
    this.rowSelection = 'multiple';

    this.getRowNodeId = (data) => {
      return data.id;
    };
    this.gridOptions = {
      ...buildDefaultGridOptions(),
      headerHeight: 48,
    };

    this.sortingOrder = ['desc', 'asc', null];

    this.defaultColDef = {
      flex: 1,
      sortable: true,
      filter: true,
      editable: false,
      resizable: true,
      headerCheckboxSelection: false,
    };

    this.overlayLoadingTemplate =
      '<span class="ag-overlay-loading-center">Please wait while your data are loading</span>';
    this.overlayNoRowsTemplate =
      '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;">' +
      this.$t('noDataAvailable') +
      '</span>';
    this.rowSelection = 'multiple';
    this.onSelectionChanged = 'onSelectionChanged';
  },
  methods: {
    fetchAssignmentGrade() {
      if (this.isNew) {
        return;
      }
      this.$loadingFlags.loadingHandler(
        LoadingFlag.AssignmentAssignmentGrade,
        AssignmentGrade.api
          .getGradeForUserOnAssignment(this.$auth.user.id, this.assignmentId)
          .catch((err) => {
            if (err && err.response && err.response.status === 403) {
              this.isGradeLocked = true;
              return;
            }
            return this.$errorReporting.errorDialogHandler(err);
          })
      );
    },
    updateSelectedRows() {
      if (this.taskAssignmentGridApi) {
        this.selectedRows = this.taskAssignmentGridApi.getSelectedRows();
      }
    },
    onGridReady({api}) {
      this.taskAssignmentGridApi = api;
      this.taskAssignmentGridApi.sizeColumnsToFit();
    },
    openPreviewTask({task}) {
      this.previewTask = task;
    },
    initializeAssignment() {
      this.cloneAssignment();
    },
    getDefaultOwnerId() {
      const options = this.ownerOptions;
      const course = options.find((courseLike) => {
        return courseLike.id === courseLike.courseId;
      });

      if (course) {
        return course.id;
      } else {
        const courseRoles = CourseRole.fullQuery({courseLike: true, role: true})
          .whereHas('courseLike', (query) => {
            query.whereIdIn(options.map((cl) => cl.id));
          })
          .get();
        const highestOrderRole = CourseRole.highestOrderRole(courseRoles);
        return highestOrderRole.courseLike.id;
      }
    },
    cloneAssignment() {
      const {id, name, overviewText, courseLikeAssignments, taskAssignments, ownerId} =
        this.assignment;
      this.assignmentData = {
        ...this.assignmentData,
        id,
        name,
        overviewText,
        ownerId,
        courseLikeAssignments: courseLikeAssignments.map((cla) => {
          const {
            id,
            courseLikeId,
            dueDate,
            startDate,
            visibleDate,
            pointValue,
            isQuizMode,
            quizModeReleaseDate,
            assignmentPolicies,
            preventPastDueSubmission,
          } = cla;
          return {
            id,
            courseLikeId,
            dueDate,
            startDate,
            visibleDate,
            pointValue,
            isQuizMode,
            quizModeReleaseDate,
            assignmentPolicies,
            preventPastDueSubmission,
          };
        }),
        taskAssignments: taskAssignments.map((ta) => {
          const {id, pointValue, bonusPoints, penaltyPoints, taskId, task, orderingIndex} = ta;
          return {
            id,
            pointValue,
            bonusPoints,
            penaltyPoints,
            taskId,
            task,
            orderingIndex,
          };
        }),
      };
      this.originalAssignmentData = Object.assign({}, this.assignmentData);
    },
    goToEditAssignment() {
      window.location = `/courses/${this.$route.params.courseId}/assignments/${this.$route.params.assignmentId}/edit`;
    },
    redirectToAssignmentSettings(assignmentId = null) {
      window.location = `/courses/${this.$route.params.courseId}/assignments/${
        assignmentId ?? this.$route.params.assignmentId
      }/settings`;
    },
    saveAssignmentHandler([skipLoseAccessCheck = false, redirectToSettings = false]) {
      this.shouldRedirectToAssignmentSettings = redirectToSettings;

      if (redirectToSettings && !this.redirectingToInertiaDialog) {
        this.redirectingToInertiaDialog = true;
        return;
      }

      let showLoseAccessDialog = false;
      if (!this.canUpdateAssignment && !skipLoseAccessCheck) {
        const assignableChangedClas = this.getChangedAssignableCourseLikes();
        showLoseAccessDialog =
          assignableChangedClas.kept.length + assignableChangedClas.added.length === 0;
      }

      if (!showLoseAccessDialog) {
        if (this.canUpdateAssignment) {
          this.saveAssignment();
        } else {
          this.saveCourseLikeAssignments();
        }
      } else {
        this.loseAccessDialogOpened = true;
      }
    },
    async saveAssignment() {
      let promiseFactory;
      if (this.isNew) {
        promiseFactory = () =>
          this.createAssignment().then((entities) => {
            this.$successSnackbar.open({
              messageTranslationKey: 'successSnackbar.createdAssignment',
              showButton: true,
            });
            const assignment = entities['Assignment'][0];
            if (this.shouldRedirectToAssignmentSettings) {
              this.redirectToAssignmentSettings(assignment.id);
              return;
            }
            this.$router.replace({
              name: ASSIGNMENT_OVERVIEW,
              params: {
                ...this.$route.params,
                assignmentId: assignment.id,
              },
            });
          });
      } else {
        promiseFactory = () =>
          this.patchAssignment().then(() => {
            if (this.shouldRedirectToAssignmentSettings) {
              this.redirectToAssignmentSettings();
              return;
            }

            this.$emit('assignment-changed');
          });
      }
      return this.$loadingFlags
        .loadingHandler(LoadingFlag.SavingAssignment, promiseFactory)
        .then((res) => {
          this.resetChanges();
          return res;
        })
        .catch(this.$errorReporting.errorDialogHandler);
    },
    async saveCourseLikeAssignments(assignmentId) {
      const promiseFactory = () => this.batchCourseLikeAssignments(assignmentId);

      return this.$loadingFlags
        .loadingHandler(LoadingFlag.SavingAssignment, promiseFactory)
        .then((res) => {
          this.resetChanges();
          return res;
        })
        .then(() => {
          this.$successSnackbar.open({
            messageTranslationKey: 'successSnackbar.saveAssignment',
            showButton: true,
          });
        })
        .catch(this.$errorReporting.errorDialogHandler);
    },
    async createAssignment() {
      const {courseLikeAssignments, ...assignmentData} = this.assignmentData;
      return Assignment.api
        .create({
          ...assignmentData,
          taskAssignments: this.assignmentData.taskAssignments.map((ta) => {
            delete ta.assignmentId;
            return ta;
          }),
        })
        .then(async (entities) => {
          if (this.canAssignAssignment) {
            const assignmentId = entities['Assignment'][0].id;
            await this.saveCourseLikeAssignments(assignmentId);
          }
          return entities;
        });
    },
    getChangedAssignableCourseLikes() {
      const claFilter = (cla) => {
        const $gate = inject(Gate.injectable);
        return $gate.can('assignAssignmentsTo', 'CourseLike', cla.courseLikeId);
      };

      return getAllChanged(
        this.assignmentData.courseLikeAssignments.filter(claFilter),
        this.originalAssignmentData.courseLikeAssignments.filter(claFilter),
        (cla1, cla2) => cla1.courseLikeId === cla2.courseLikeId
      );
    },
    batchCourseLikeAssignments(assignmentId) {
      const changedClas = this.getChangedAssignableCourseLikes();

      assignmentId = assignmentId ?? this.assignmentId;
      changedClas.added.forEach((cla) => (cla.assignmentId = assignmentId));
      changedClas.kept.forEach((cla) => (cla.assignmentId = assignmentId));

      const batchRequest = buildBatch(changedClas, 'id', 'courseLikeId');
      return CourseLikeAssignment.api.batch(batchRequest, {
        includePolicies: true,
      });
    },
    patchAssignment() {
      const {courseLikeAssignments, ...assignmentData} = this.assignmentData;
      return Assignment.api
        .patch(this.assignmentId, assignmentData)
        .then(async (res) => {
          if (this.canAssignAssignment) {
            await this.saveCourseLikeAssignments(this.assignmentId);
          }
          return res;
        })
        .then((res) => {
          for (const removedTaskAssignment of this.removedTaskAssignments) {
            TaskAssignment.delete(removedTaskAssignment.id);
          }

          for (const removedCla of this.removedCourseLikeAssignments) {
            CourseLikeAssignment.delete(removedCla.id);
          }

          this.removedTaskAssignments = [];

          return res;
        })
        .catch(this.$errorReporting.errorDialogHandler)
        .then(() => {
          this.$successSnackbar.open({
            messageTranslationKey: 'successSnackbar.saveAssignment',
            showButton: true,
          });
        });
    },
    onRemoveSelected() {
      this.removeTasks(this.selectedRows);
    },
    removeTasks(taskAssignments) {
      for (const taskAssignment of taskAssignments) {
        if (taskAssignment.id !== null) {
          this.removedTaskAssignments.push(taskAssignment);
        }
      }
      this.taskAssignmentGridApi.applyTransaction({remove: taskAssignments});
      this.refreshTaskAssignmentsOrder();
    },
    remove(item) {
      this.sectionChips.splice(this.sectionChips.indexOf(item), 1);
      this.sectionChips = [...this.sectionChips];
    },
    onRowDragEnd() {
      this.refreshTaskAssignmentsOrder();
    },
    onAddTasks({tasks}) {
      const taskAssignments = tasks.map((task) => {
        return new TaskAssignment({
          pointValue: 1,
          bonusPoints: 0,
          penaltyPoints: 0,
          orderingIndex: 0,
          taskId: task.id,
          task: task,
        });
      });
      // Trying to push to taskAssignments directly causes TypeError, so set a copy
      this.assignmentData.taskAssignments = [
        ...this.assignmentData.taskAssignments,
        ...taskAssignments,
      ];
      this.$nextTick(() => {
        this.refreshTaskAssignmentsOrder();
      });
    },
    refreshTaskAssignmentsOrder() {
      this.taskAssignmentGridApi.forEachNode((node, index) => {
        node.data.orderingIndex = index + 1;
      });
      this.taskAssignmentGridApi.refreshCells();
    },
    saveAndPreview() {
      const changesInAssignmentData = this.hasChangesInAssignmentData;

      if (changesInAssignmentData) {
        this.saveAssignment()
          .then(() => {
            window.location = `/web/courses/${this.$route.params.courseId}/assignments/${this.$route.params.assignmentId}/tasks/first`;
          })
          .catch(this.$errorReporting.errorDialogHandler);
      } else {
        window.location = `/web/courses/${this.$route.params.courseId}/assignments/${this.$route.params.assignmentId}/tasks/first`;
      }
    },
    resetChanges() {
      this.hasChangesInAssignmentData = false;
    },
    getMissingFormData() {
      const {name, taskAssignments, courseLikeAssignments, ownerId} = this.assignmentData;
      const missingFormData = [];

      if (!name) {
        missingFormData.push({
          missingData: 'name',
        });
      }

      if (!ownerId) {
        missingFormData.push({
          missingData: 'owner',
        });
      }

      if (taskAssignments.length === 0) {
        missingFormData.push({
          missingData: 'tasks',
        });
      } else {
        let badDueDate = false;
        let badWeight = false;
        courseLikeAssignments.forEach((cla) => {
          const startDate = moment(cla.startDate);
          const dueDate = moment(cla.dueDate);
          if (dueDate.isSameOrBefore(startDate)) {
            badDueDate = true;
          }

          if (cla.pointValue < 0) {
            badWeight = true;
          }
        });

        if (badDueDate) {
          missingFormData.push({
            missingData: 'dueDateNotAfterStartDate',
          });
        }

        if (badWeight) {
          missingFormData.push({
            missingData: 'negativeWeight',
          });
        }
      }

      return missingFormData;
    },
    cancelName() {
      this.inlineEdits.editAssignmentName = false;
    },
    editName() {
      this.assignmentData.queuedName = this.assignmentData.name;
      this.inlineEdits.editAssignmentName = true;
    },
    saveName() {
      this.assignmentData.name = this.assignmentData.queuedName;
      this.inlineEdits.editAssignmentName = false;
    },
    editOverviewText() {
      this.assignmentData.queuedOverviewText = this.assignmentData.overviewText;
      this.inlineEdits.editOverviewText = true;
    },
    saveOverviewText() {
      this.assignmentData.overviewText = this.assignmentData.queuedOverviewText;
      this.inlineEdits.editOverviewText = false;
    },
    cancelOverviewText() {
      this.inlineEdits.editOverviewText = false;
    },
    onNameKeydown(event) {
      if (event.key === 'Enter') {
        this.saveName();
      } else if (event.key === 'Escape') {
        this.cancelName();
      }
    },
    onEditOverviewKeydown(event) {
      if (event.key === 'Enter') {
        this.saveOverviewText();
      } else if (event.key === 'Escape') {
        this.cancelOverviewText();
      }
    },
  },
};
</script>

<style>
.task-buttons {
  display: inline;
  position: relative;
  top: 32px;
}

.buttons-full-width {
  width: 100%;
}

.hide-header-preview {
  width: 0 !important;
  min-width: 0 !important;
  border: 0 !important;
}

.ag-row:not(.ag-row-hover) .hide-preview {
  width: 0 !important;
  padding: 0 !important;
}

.ag-row:not(.ag-row-hover) .ag-cell .hide-preview {
  width: 0 !important;
  padding: 0 !important;
}

.ag-column-style-link {
  color: #3378af;
  font-weight: 400;
  font-size: 13px;
}

.v-card__text {
  line-height: 28px;
}

.v-picker__body {
  background-color: transparent !important;
}

.tab-text-material {
  padding: 16px 0px 0px 0px !important;
}

.v-tabs:not(.v-tabs--vertical):not(.v-tabs--right)
  > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes)
  .v-slide-group__prev {
  display: none !important;
}

.v-slide-group__prev {
  background-color: transparent !important;
}

.v-date-picker-header {
  margin-bottom: 32px;
}

.v-text-field {
  padding-top: 0px !important;
  margin-right: -20px !important;
}

.theme--dark .v-picker__body--no-title {
  background-color: #121212 !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  padding-right: 0px !important;
  margin-right: 0px !important;
}

.tab-text-material {
  padding: 16px 0px 0px 0px !important;
}

.v-tabs:not(.v-tabs--vertical):not(.v-tabs--right)
  > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes)
  .v-slide-group__prev {
  display: none !important;
}

.ag-theme-material .ag-header-cell-label .ag-header-cell-text {
  height: auto;
  overflow-wrap: normal;
  white-space: normal;
  padding-bottom: 5px;
  line-height: 15px;
}

.ag-theme-material .ag-header-cell-text {
  font-size: 13px !important;
  font-weight: 600 !important;
  color: #d83d0e !important;
}

.ag-theme-material .ag-checkbox-input-wrapper {
  background-color: #ffffff !important;
}

.ag-row-hover {
  background-color: #ccddeb !important;
}

.ag-set-filter-list {
  width: 300px !important;
}

.ag-theme-material .ag-cell-data-changed {
  background-color: #d83d0e !important;
}

.ag-theme-material .ag-ltr .ag-row-drag,
.ag-theme-material .ag-ltr .ag-selection-checkbox,
.ag-theme-material .ag-ltr .ag-group-expanded,
.ag-theme-material .ag-ltr .ag-group-contracted {
  margin-right: 30px !important;
}

.stemble-task-grid .ag-theme-material .ag-cell-inline-editing {
  padding: 2px 4px !important;
  height: auto !important;
}
</style>
